<template>
    <section class="support-container" id="contact" :style="isPopup ? { 'filter': 'blur(30px)' } : { 'filter': 'none' }">
        <div class="support-content" id="get-in-touch">
            <h3 class="header title">Get In Touch With Us!</h3>
            <!-- <p class="sub-title">We are empowering the Metaverse and GameFi through </p> -->
            <div class="button-container" @click="openPopup()">
                <button class="button btn">Register</button>
                <div class="elipse-one"></div>
                <div class="elipse-two"></div>
            </div>
        </div>
        <Register :visible="popup" @close="close"></Register>
    </section>
</template>
<script>
import Register from '../components/Modal/Register.vue';
import { mapState } from 'vuex'

export default {
    name: "GetInTouch",
    data() {
        return {
            popup: false,
        };
    },
    computed: {
        ...mapState({
            isPopup: state => state.isPopup,
        }),
    },
    methods: {
        close() {
            this.$store.dispatch('setPopup', false);
            this.popup = false;
        },
        openPopup() {
            this.$store.dispatch('setPopup', true);
            this.popup = true;
        }
    },
    components: { Register }
}
</script>
<style scoped>
.support-container {
    width: 100%;
    overflow: hidden;
    padding-top: 5%;
    padding-bottom: 10%;
}

.support-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: 50px !important;
    text-align: center;
    font-family: 'Orbitron', sans-serif !important;
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo>img {
    width: 70%;
    height: 100%;
    object-fit: contain;
}

.sub-title {
    width: 80%;
    margin: 10px 0px 0px 0px;
    text-align: center;
    font-family: 'Chakra Petch', sans-serif;
    color: #E0E0E0;
    font-size: 22px;
    font-weight: 500;
}

.btn {
    width: 100%;
    height: 100%;
    font-size: 22px;
    font-weight: 500;
}

.button-container {
    width: 15%;
    height: 40px;
    position: relative;
    margin-top: 20px;
}

.elipse-one {
    width: 30px;
    height: 30px;
    background: #4629C6;
    filter: blur(23px);
    position: absolute;
    top: 0;
    left: 10px;
}

.elipse-two {
    background: #00F2F6;
    filter: blur(23px);
    width: 20px;
    height: 20px;
    bottom: -14px;
    right: 0;
    position: absolute;
}

.header {
    background: linear-gradient(90.45deg, #00FFBF -2.93%, #1657DC 95.78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-family: 'Orbitron', sans-serif !important;
    font-weight: 800;
}

@media only screen and (max-width: 600px) {
    .support-container {
        width: 100%;
        overflow: hidden;
        padding-top: 20%;
        padding-bottom: 20%;
    }

    .support-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .title {
        font-size: 30px !important;
        text-align: center;
        font-family: 'Orbitron', sans-serif !important;
        font-weight: 800;
        width: 75%;
    }

    .logo {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .logo>img {
        width: 70%;
        height: 100%;
        object-fit: contain;
    }

    .sub-title {
        width: 80%;
        margin: 10px 0px 0px 0px;
        text-align: center;
        font-family: 'Chakra Petch', sans-serif;
        color: #E0E0E0;
        font-size: 14px;
        font-weight: 500;
    }

    .btn {
        width: 100%;
        height: 100%;
        font-size: 22px;
        font-weight: 500;
    }

    .button-container {
        width: 40%;
        height: 40px;
        position: relative;
        margin-top: 10px;
        margin-bottom: 5%;
    }

    .elipse-one {
        width: 30px;
        height: 30px;
        background: #4629C6;
        filter: blur(23px);
        position: absolute;
        top: 0;
        left: 10px;
    }

    .elipse-two {
        background: #00F2F6;
        filter: blur(23px);
        width: 20px;
        height: 20px;
        bottom: -14px;
        right: 0;
        position: absolute;
    }

    .header {
        background: linear-gradient(90.45deg, #00FFBF -2.93%, #1657DC 95.78%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        font-family: 'Orbitron', sans-serif !important;
    }
}
</style>